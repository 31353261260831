import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "rapidly-build-beautiful-and-accessible-experiences-the-carbon-kit-contains-all-resources-you-need-to-get-started"
    }}>{`Rapidly build beautiful and accessible experiences. The Carbon kit contains all resources you need to get started.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Get the kit</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Start designing</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Anatomy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Info</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "get-the-kit"
    }}>{`Get the kit`}</h2>
    <h4 {...{
      "id": "1-install-sketch"
    }}>{`1. `}<strong parentName="h4">{`Install Sketch`}</strong>{`.`}</h4>
    <p>{`To design with Carbon you must have the `}<strong parentName="p">{`most recent version`}</strong>{` of `}<a parentName="p" {...{
        "href": "https://www.sketchapp.com/"
      }}>{`Sketch`}</a>{` installed.`}</p>
    <h4 {...{
      "id": "2-choose-a-theme"
    }}>{`2. `}<strong parentName="h4">{`Choose a theme`}</strong>{`.`}</h4>
    <p>{`There are `}<a parentName="p" {...{
        "href": "/guidelines/color/overview#themes"
      }}>{`four Carbon themes`}</a>{`, two light (White and Gray 10) and two dark (Gray 90 and Gray 100). Each theme lives in its own Sketch library. You can subscribe to as many libraries as you’d like.`}</p>
    <Row className="tile--group" mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" noGutterSm mdxType="Column">
    <ResourceCard title="Get the White theme" href="sketch://add-library/cloud/JaVzz" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png",
              "srcSet": ["/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" noGutterSm mdxType="Column">
    <ResourceCard title="Get the Gray 10 theme" href="sketch://add-library/cloud/Onwv2" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png",
              "srcSet": ["/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
  </Column>
      <Column offsetLg="4" colLg="4" noGutterSm mdxType="Column">
  <ResourceCard title="Get the Gray 90 theme" href="sketch://add-library/cloud/eo37p" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png",
              "srcSet": ["/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" noGutterSm mdxType="Column">
    <ResourceCard title="Get the Gray 100 theme" href="sketch://add-library/cloud/d13Ll" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png",
              "srcSet": ["/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
  </Column>
    </Row>
    <br />
    <br />
    <h4 {...{
      "id": "3-bring-in-additional-colors-and-icons"
    }}>{`3. `}<strong parentName="h4">{`Bring in additional colors and icons`}</strong>{`.`}</h4>
    <p>{`The full icon library and additional color collections live in the IBM Design Language library.`}</p>
    <Row className="tile--group" mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" noGutterSm mdxType="Column">
    <ResourceCard title="Get the IBM Design Language library" href="sketch://add-library/cloud/75VZZ" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png",
              "srcSet": ["/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <br />
    <br />
    <h4 {...{
      "id": "4-download-the-grid-templates"
    }}>{`4. `}<strong parentName="h4">{`Download the grid templates`}</strong>{`.`}</h4>
    <p>{`Visit the `}<a parentName="p" {...{
        "href": "https://sketch.cloud/s/ngV7z"
      }}>{`Sketch library`}</a>{` page and choose `}<inlineCode parentName="p">{`Download Document`}</inlineCode>{` from the right-side panel.`}</p>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Download the IBM Grid template" href="https://sketch.cloud/s/ngV7z" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png",
              "srcSet": ["/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Download the UI Shell template" href="https://sketch.cloud/s/EjVmA" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png",
              "srcSet": ["/static/25ef536ecb3b4de186e21f7a970c20ee/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <br />
    <br />
    <p>{`Open the file in Sketch. Navigate to `}<inlineCode parentName="p">{`File → Save as Template`}</inlineCode>{`. You can now access the saved grid template at `}<inlineCode parentName="p">{`File → New file from Template`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "start-designing"
    }}>{`Start designing`}</h2>
    <p>{`To get started with Carbon, familiarize yourself with the contents of each library.`}</p>
    <h4 {...{
      "id": "start-with-the-grid"
    }}><strong parentName="h4">{`Start with the grid`}</strong>{`.`}</h4>
    <p>{`At the top of your screen, navigate to `}<inlineCode parentName="p">{`File → New file from Template`}</inlineCode>{` and select your grid template. You’ll always be able to find the template here.`}</p>
    <h4 {...{
      "id": "icons-and-color-swatches"
    }}><strong parentName="h4">{`Icons and color swatches`}</strong>{`.`}</h4>
    <p>{`Symbols from both the IBM Design Language library and the Carbon library are accessible from any Sketch document. Navigage to `}<inlineCode parentName="p">{`Insert → Symbols → Carbon Design System`}</inlineCode>{` or `}<inlineCode parentName="p">{`IBM Design Language`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "youre-all-set"
    }}><strong parentName="h4">{`You’re all set`}</strong>{`.`}</h4>
    <p>{`Refer to the content below for a more detailed breakdown of the kit, or head to Sketch to begin designing.`}</p>
    <h2 {...{
      "id": "anatomy"
    }}>{`Anatomy`}</h2>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://sketch.cloud/s/JaVzz/"
      }}>{`Sketch library`}</a>{` page for an overview of the kit.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Grid</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Symbols</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Text styles</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Layer styles</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "grid"
    }}>{`Grid`}</h3>
    <p>{`The IBM 2x Grid is the framework for laying out all visual elements in Carbon.`}</p>
    <img {...{
      "src": "/grid-2-78f2bcb634d36680d173463377316538.gif",
      "alt": "2x Grid overview"
    }}></img>
    <p>{`All designs should start with the 2x Grid template. Once you have `}<a parentName="p" {...{
        "href": "#get-the-kit"
      }}>{`saved the grid`}</a>{` as a template, navigate to `}<inlineCode parentName="p">{`File → New file from Template`}</inlineCode>{` and select the grid template.`}</p>
    <p>{`See our `}<a parentName="p" {...{
        "href": "/guidelines/layout"
      }}>{`layout guidelines`}</a>{` for a more detailed walkthrough of the 2x Grid.`}</p>
    <h4 {...{
      "id": "structure"
    }}>{`Structure`}</h4>
    <p>{`The grid template includes templates for all breakpoints along with the necessary formulas to design for custom screen sizes.`}</p>
    <p>{`To toggle the grid on and off, use `}<inlineCode parentName="p">{`Control+G`}</inlineCode>{`. To toggle the layout guide, use `}<inlineCode parentName="p">{`Control+L`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "symbols"
    }}>{`Symbols`}</h3>
    <p>{`Carbon `}<a parentName="p" {...{
        "href": "/components/overview"
      }}>{`components`}</a>{`, `}<a parentName="p" {...{
        "href": "/contributing/add-ons"
      }}>{`add-ons`}</a>{`, and `}<a parentName="p" {...{
        "href": "/guidelines/iconography/library"
      }}>{`icons`}</a>{` live in the design kit as Sketch `}<em parentName="p">{`symbols`}</em>{`.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "63.235294117647065%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Carbon symbol menu navigation",
        "title": "Carbon symbol menu navigation",
        "src": "/static/b98d0a92e666faa6431f168c9befc147/fb070/carbon-kit.png",
        "srcSet": ["/static/b98d0a92e666faa6431f168c9befc147/d6747/carbon-kit.png 288w", "/static/b98d0a92e666faa6431f168c9befc147/09548/carbon-kit.png 576w", "/static/b98d0a92e666faa6431f168c9befc147/fb070/carbon-kit.png 1152w", "/static/b98d0a92e666faa6431f168c9befc147/fb104/carbon-kit.png 1728w", "/static/b98d0a92e666faa6431f168c9befc147/902fb/carbon-kit.png 2304w", "/static/b98d0a92e666faa6431f168c9befc147/292c2/carbon-kit.png 2720w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "library-menu-navigation"
    }}>{`Library menu navigation`}</h4>
    <p>{`There are two kinds of symbols — library symbols and document symbols. Library symbols are available in any Sketch document, while document symbols are specific to the document in which they are found.`}</p>
    <p>{`From the `}<inlineCode parentName="p">{`Insert`}</inlineCode>{` menu, select `}<inlineCode parentName="p">{`Symbols → Carbon Design System`}</inlineCode>{` to add symbols onto your page.`}</p>
    <p>{`Carbon symbols are built to be flexible, and designers should not detach symbols from the library. Once a symbol is detached, you will no longer receive updates as they are released.`}</p>
    <h4 {...{
      "id": "structure-1"
    }}>{`Structure`}</h4>
    <p>{`Symbols are organized by component; after selecting a component, you’ll see the variations and states of that component.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`To add the whole component, select the desired variant and place it on your artboard.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For even more configurablility, use the individual building blocks found under the `}<inlineCode parentName="p">{`Items`}</inlineCode>{` folder within the symbol. Be sure your custom design adheres to our `}<a parentName="p" {...{
            "href": "/guidelines/accessibility/overview"
          }}>{`design guidelines`}</a>{`.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "text-styles"
    }}>{`Text styles`}</h3>
    <p>{`Text styles are based on typography rules defined by the `}<a parentName="p" {...{
        "href": "https://www.ibm.com/design/language/elements/type-basics"
      }}>{`IBM Design Language`}</a>{`.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "63.235294117647065%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Text styles navigation",
        "title": "Text styles navigation",
        "src": "/static/bf9a5497394c033f5a6928a894984f94/fb070/text-styles-1.png",
        "srcSet": ["/static/bf9a5497394c033f5a6928a894984f94/d6747/text-styles-1.png 288w", "/static/bf9a5497394c033f5a6928a894984f94/09548/text-styles-1.png 576w", "/static/bf9a5497394c033f5a6928a894984f94/fb070/text-styles-1.png 1152w", "/static/bf9a5497394c033f5a6928a894984f94/fb104/text-styles-1.png 1728w", "/static/bf9a5497394c033f5a6928a894984f94/902fb/text-styles-1.png 2304w", "/static/bf9a5497394c033f5a6928a894984f94/292c2/text-styles-1.png 2720w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "library-menu-navigation-1"
    }}>{`Library menu navigation`}</h4>
    <p>{`From the main menu select `}<inlineCode parentName="p">{`Insert → Text Styles → Carbon Design System`}</inlineCode>{` to insert a Carbon type token text style.`}</p>
    <p>{`Text styles within components are carefully considered. We do not recommend detaching the symbol to change default styles.`}</p>
    <h4 {...{
      "id": "structure-2"
    }}>{`Structure`}</h4>
    <p>{`Carbon text styles in Sketch are organized similarly to symbols.`}</p>
    <ul>
      <li parentName="ul">{`The first text style level displays `}<a parentName="li" {...{
          "href": "/guidelines/themes"
        }}>{`theme options`}</a>{` (i.e., white theme).`}</li>
      <li parentName="ul">{`The second level shows the `}<a parentName="li" {...{
          "href": "/guidelines/typography"
        }}>{`Carbon type`}</a>{` tokens (i.e., body-long-01).`}</li>
      <li parentName="ul">{`The third level selects the type color, which is labeled with a `}<a parentName="li" {...{
          "href": "/guidelines/color"
        }}>{`Carbon color`}</a>{` token name.`}</li>
    </ul>
    <h3 {...{
      "id": "layer-styles"
    }}>{`Layer styles`}</h3>
    <p>{`Layer styles are color selections for any shape in Sketch.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "78.125%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Layer styles navigation example",
        "title": "Layer styles navigation example",
        "src": "/static/74d5743e0a4297f20d830a0348f622d1/fb070/layer-style-1.png",
        "srcSet": ["/static/74d5743e0a4297f20d830a0348f622d1/d6747/layer-style-1.png 288w", "/static/74d5743e0a4297f20d830a0348f622d1/09548/layer-style-1.png 576w", "/static/74d5743e0a4297f20d830a0348f622d1/fb070/layer-style-1.png 1152w", "/static/74d5743e0a4297f20d830a0348f622d1/c3e47/layer-style-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "library-menu-navigation-2"
    }}>{`Library menu navigation`}</h4>
    <p><a parentName="p" {...{
        "href": "/guidelines/color/overview"
      }}>{`Color`}</a>{` in Carbon is carefully considered, and we discourage custom layer styles.`}</p>
    <p>{`The first layer is the theme selection (i.e., 01 White theme). The second layer is core Carbon color tokens (i.e., field-01) for the selected them. The interaction state tokens are nested in a third layer under “state”.`}</p>
    <h2 {...{
      "id": "info"
    }}>{`Info`}</h2>
    <p>{`If you’re brand new to Sketch, they offer some great `}<a parentName="p" {...{
        "href": "https://www.sketchapp.com/docs/"
      }}>{`tutorials and help docs`}</a>{`.`}</p>
    <h3 {...{
      "id": "updates"
    }}>{`Updates`}</h3>
    <p>{`We make small adjustments and bug fixes to the kit on a regular basis. When we make a change to any of the libraries, you will be notified via a red pop-up in the top right corner of the Sketch window.`}</p>
    <p>{`The updates are opt-in, however we recommend you keep your kit as up to date as possible. Once a change is accepted, you will not be able to revert to the previous version.`}</p>
    <h3 {...{
      "id": "migration"
    }}>{`Migration`}</h3>
    <p>{`If you’re using an older version of Carbon, check out the v10 `}<a parentName="p" {...{
        "href": "/updates/v10-migration/designers"
      }}>{`migration docs`}</a>{` when you’re ready to make the switch.`}</p>
    <h3 {...{
      "id": "support"
    }}>{`Support`}</h3>
    <p>{`Can’t find an answer to your question? Open up an issue on `}<a parentName="p" {...{
        "href": "https://github.com/IBM/carbon-components/issues/new/choose"
      }}>{`GitHub`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      